
import { Component, Vue } from 'vue-property-decorator'
import { phone } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { AgentDetail } from '@/types/agent'
import { Area } from '@/types/common'

@Component({
  name: 'AgentAdd'
})
export default class extends Vue {
  private title = '新增代理商'
  private options: Array<Area> = []
  private position: string[] = []
  private roleList: Array<{ role: string; roleName: string }> = []
  private userList: Array<{ userId: string; name: string }> = []
  private info: AgentDetail = {
    agentName: '',
    roleId: '',
    agentId: '',
    contacts: '',
    contactNumber: '',
    province: '',
    city: '',
    area: '',
    address: '',
    remarks: ''
  }

  private rules = {
    agentName: [
      { required: true, message: '请输入代理商名称', trigger: ['blur', 'change'] }
    ],
    roleId: [
      { required: true, message: '请选择系统角色', trigger: ['blur', 'change'] }
    ],
    agentId: [
      { required: true, message: '请选择系统账号', trigger: ['blur', 'change'] }
    ],
    contacts: [
      { required: true, message: '请输入联系人', trigger: ['blur', 'change'] }
    ],
    contactNumber: [
      { required: true, message: '请输入联系电话', trigger: ['blur', 'change'] },
      { validator: phone, trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  get agentId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getRoleList()
    this.getRegions()
    if (this.agentId) {
      this.title = '编辑代理商'
      this.getDetail()
    }
  }

  // 获取角色列表
  getRoleList () {
    this.$axios.get(this.$apis.role.selectIparkAdminRoleByList, {}).then((res) => {
      this.roleList = res || []
    })
  }

  getRegions () {
    this.$axios.get<Area[]>(this.$apis.common.getRegion).then(res => {
      this.options = res
    })
  }

  // 获取用户列表
  getUserList (roleId: string) {
    this.$axios.get(this.$apis.userAdmin.selectIparkUserByList, {
      adminRole: roleId
    }).then((res) => {
      this.userList = res.list || []
    })
  }

  // 省市区变化
  positionChange (value: string[]) {
    this.info.province = value[0] || ''
    this.info.city = value[1] || ''
    this.info.area = value[2] || ''
  }

  // 代理商详情
  getDetail () {
    this.$axios.get(this.$apis.agent.selectAgentByAgentId, {
      agentId: this.agentId
    }).then((res) => {
      this.position = [res.province, res.city, res.area]
      this.info = res
    })
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.addAgent()
      }
    })
  }

  // 用户详情
  addAgent () {
    this.submitShow = true
    const url = this.agentId
      ? this.$apis.agent.updateAgent
      : this.$apis.agent.insertAgent
    this.$axios.post(url, this.info).then(() => {
      this.submitShow = false
      this.$message.success('保存成功')
      this.$router.push({ name: 'agentList' })
    }).finally(() => {
      this.submitShow = false
    })
  }
}
